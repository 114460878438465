import React,{ useState,useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import confServeur from "../Components/Serveur/confServeur";
import imgProfil from '../assets/img/profile-img.jpg'
import Isotope from 'isotope-layout';
import 'bootstrap-icons/font/bootstrap-icons.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import GLightbox from 'glightbox';
import test from '../assets/img/portfolio/portfolio-1.jpg'







export default function Portfolio() {
  const [projects, setProjets] = useState([]);
  const isotope = useRef()
  const urlServeur = confServeur.server;
  const urlImage = confServeur.storage;
  const [filterKey, setFilterKey] = useState('*')

useEffect(()=>{
  
  isotope.current = new Isotope('.portfolio-container', {
    itemSelector: '.portfolio-item',
    layoutMode: 'fitRows', // Assurez-vous de spécifier un mode de disposition
  });
  

    // Initialisez GLightbox
    const lightbox = GLightbox({
      selector: '.portfolio-lightbox',
    });

    // Initialisez AOS
  AOS.init({
    duration: 1000,
    easing: 'ease-in-out',
    once: true,
    mirror: false,
  });

  return () => isotope.current.destroy()

}, []);

// handling filter key change
React.useEffect(() => {
  console.log('filter key',filterKey)
  filterKey === '*'
    ? isotope.current.arrange({filter: `*`})
    : isotope.current.arrange({filter: `.${filterKey}`})
}, [filterKey])


//gestion des clés de filtres
const handleFilterKeyChange = key => () => setFilterKey(key)


//j'appelle les projets
useEffect(() => {
  fetch(`${urlServeur}/portfolio`)
    .then((res) => res.json())
    .then((data) => setProjets(data))
    
    .catch((err) => console.log(err));
    
}, []);




  return (
    <article>
        {/* ======= Portfolio =======  */}
        <section id="portfolio" className="portfolio section-bg">
      <div className="container">

        <div className="section-title">
          <h2>Portfolio</h2>
          <p>Vous trouverez ci-dessous quelques projets d'horizons variés.</p>
        </div>

        <div className="row" data-aos="fade-up">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li onClick={handleFilterKeyChange('*')} >All</li>
              <li onClick={handleFilterKeyChange('filter-app')}>App</li>
              <li onClick={handleFilterKeyChange('filter-web')}>Web</li>
              <li onClick={handleFilterKeyChange('filter-card')}>Design</li>
            </ul>
          </div>
        </div>

        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">
        {projects && projects.length > 0 ? (
  projects.map((project) => (
  <div className={`col-lg-4 col-md-6 portfolio-item ${project.category}`} key={project.id}>
            <div className="portfolio-wrap">
              <img src={`${urlServeur}${urlImage}${project.image}`} className="img-fluid" alt={project.title}/>
              <div className="portfolio-links">
                <a href={`${urlServeur}${urlImage}${project.image}`} data-gallery="portfolioGallery" className="portfolio-lightbox" title={project.title}><i className="bi bi-link"></i></a>
                <a href="portfolio-details.html" title="En voir plus"><i className="bi bi-link"></i></a>
              </div>
            </div>
          </div>
           ))
) : (
  <p>Aucun projet n'est disponible pour le moment.</p>
)}
          

          

         

        </div>

      </div>
    </section>
   {/* End Portfolio  */}

    </article>
  );
}
