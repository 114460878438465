import { Link } from "react-router-dom";
import imgProfil from '../assets/img/profile-img.jpg'
import { useNavigate } from "react-router-dom";
import { useEffect} from "react";

export default function Aboutme() {

  const Navigate = useNavigate()

  //si l'utilisateur a déjà un localStorage pour avoir visité la page d'accueil, alors il accède directement au site sans le réafficher
  useEffect(() => {
   const visited = JSON.parse(localStorage.getItem("pre-accueil"));
   if (visited===null) {
     Navigate('/acc')
   }
 }, []);





  return (
    <article>
        {/* ======= About Section =======  */}
    <section id="about" className="about">
      <div className="container">

        <div className="section-title">
          <h2>Me concernant</h2>
          <p>Autonome, curieux et sérieux, j’aime travailler dans divers domaines tel que le numérique, la culture et l'art. En quête d'un nouveau challenge, je me suis lancé dans une formation de conception et développeur d'application web.
Soyez la prochaine société à s’inscrire dans mon Histoire, et moi, dans la vôtre !</p>
        </div>

        <div className="row">
          <div className="col-lg-4" data-aos="fade-right">
            <img src={imgProfil} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
            <h3>Concepteur et Développeur d'application web</h3>
            <p className="fst-italic">
              Malgré la force disruptive de l'intelligence artificielle, je suis convaincu que la programmation reste le fer de lance de la révolution technologique.
            </p>
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li><i className="bi bi-chevron-right"></i> <strong>Mail:</strong> <span>Dembothiam@hotmail.com</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Tél:</strong> <span>+33 7 63 59 13 81</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>autre site:</strong> <span>www.daragan.com</span></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>37 ans</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Niveau:</strong> <span>Bac+3/4</span></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Permis:</strong> <span>B, véhiculé</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
   {/* End About Section  */}

    </article>
  );
}
