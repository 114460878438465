import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import confServeur from "../Components/Serveur/confServeur";

export default function AjoutProjet() {
  const urlServeur = confServeur.server;
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const navigate = useNavigate();

  const validationForm = (e) => {
    e.preventDefault();

    // création de l'objet à renvoyé en base de données
    const project = new FormData();
    project.append("title", title);
    project.append("description", description);
    project.append("type", type);
    project.append("image", image);

    console.log(project);
// renvoi de l'objet en BD
    fetch(`${urlServeur}/portfolio/add`, {
      method: "POST",
      body: project,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data) {
          navigate(`/portfolio/${data.slug}`);
        }
      })
      .catch((err) =>
        alert("une erreur est survenue. Merci de vérifier votre réponse")
      );
  };

  return (
    <article>
      <h3>Déclarer un nouveau projet</h3>
      <form encType="multipart/form-data" onSubmit={validationForm}>
        <label htmlFor="title">Titre</label>
        <input
          type="text"
          name="title"
          placeholder="Titre"
          minLength="2"
          maxLength="50"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          required
        />
        <input
          type="file"
          accept=".png, .jpg,.webp, .jpeg,.pdf"
          name="image"
          id=""
          onChange={(e) => {
            setImage(e.target.files[0]);
          }}
        />
        <label htmlFor="type">Type de projet</label>
        <input
          type="text"
          name="type"
          required
          onChange={(e) => {
            setType(e.target.value);
          }}
        />
        <label htmlFor="description">Description</label>
        <textarea
          name="description"
          cols="30"
          rows="10"
          required
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        ></textarea>
        <button type="submit">Enregistrer</button>
      </form>
    </article>
  );
}
