//création d'une constante du chemin des images et du fetch afin d'optimiser le changement pour la mise en ligne


const pathServeur = {server:'http://apiportfolio.dembathiam.com:3005',
storage:'/media/'
}


export default pathServeur


    