import Typed from "typed.js";
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";



export default function Prehome() {


  const [homeValidate, setHomeValidate] = useState(false);
  const navigate = useNavigate();
  const el = useRef(null);

 

  useEffect(() => {
    const typed = new Typed(el.current, {
      // listes des termes affichés en page d'acceuil via la machine à écrire
      strings: [
        "Inventer",
        "créer",
        "Innover",
        "sécuriser",
        "Développer",
      ],
      loop: false,
      typeSpeed: 100,
      backSpeed: 80,
      backDelay: 1500,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  function handleAccess() {
    setHomeValidate(true);
    window.localStorage.setItem("pre-accueil", true);
    navigate("/");
  }
  return (
    <div className="acc-pre flex">
      
    <div className="pre-accueil">
      {/* hexagone d'image */}
      <section>
      <Link to="/" className="logo">
        <img src="../../../../Images/logo/logodaragan.png" alt="" /><h2>Demba<br />Thiam</h2></Link>
      <h2>Portfolio</h2>
       {/*  <h1>Daragan</h1>
        <h2>Le plaisir de la créativité</h2>
        {/* div en mode machine à écrire */}
        <div>
          <h3 ref={el}></h3>
        </div> 
        
        <button
          className="ouverturesite button-secondary"
          onClick={handleAccess}
        >
          Accéder au site
        </button>
      </section>
    </div>
    {/* partie portrait de la page de pré-accueil */}
    <div className="portrait-container">
        <div className="portrait-accueil">
          {/* portrait en hexagone */}
          <img src="../../../Images/Design/portrait_accueil.JPG" alt="" />
          </div>
          {/* hexagone d'arrière plan */}
        <div className="hexagone-haut"></div>
      </div>
    </div>
  );
}
