import {Link, useLocation} from "react-router-dom"
import { useState, useEffect } from "react";
import {faLinkedin,faSquareFacebook,} from '@fortawesome/free-brands-svg-icons'
import{faEnvelope,faMobile,faPersonCircleExclamation,faGlobe} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imgProfil from '../../assets/img/profile-img.jpg'
import 'bootstrap-icons/font/bootstrap-icons.css';





export default function Header() {
  const [currentPage, setCurrentPage] = useState(""); // État pour la page active
  const location = useLocation(); //  useLocation pour obtenir l'emplacement actuel


  // Fonction pour définir la page active en fonction de l'emplacement actuel
  const setActivePage = () => {
    const pathname = location.pathname;

    if (pathname === "/") {
      setCurrentPage("me-concernant");
    } else if (pathname === "/competences") {
      setCurrentPage("competences");
    } else if (pathname === "/cv") {
      setCurrentPage("cv");
    } else if (pathname === "/portfolio") {
      setCurrentPage("portfolio");
    } else if (pathname === "/contact") {
      setCurrentPage("contact")
    } else {
      setCurrentPage("");
    }
  };


  useEffect(() => {
    setActivePage();
  }, [location]);

    return (
       
        <header id="header">
          <div className="d-flex flex-column">
      
            <div className="profile">
              <img src={imgProfil} alt="portrait de Demba" className="img-fluid rounded-circle" />
              <h1 className="text-light"><Link to="/">Demba THIAM</Link></h1>
              <div className="social-links mt-3 text-center">
               
                <a href="https://www.facebook.com/daragan.dooms.3/" className="facebook "><i className="bi bi-facebook"></i></a>
                <a href="https://github.com/daragand/" className="google-plus"><i className="bi bi-github"></i></a>
                <a href="https://www.linkedin.com/in/demba-thiam-cda/" className="linkedin"><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
      
            <nav id="navbar" className="nav-menu navbar">
              <ul>
                <li><Link to="/" className={currentPage === "me-concernant" ? "nav-link scrollto active" : "nav-link scrollto"}><i className="bi bi-person-badge-fill"></i> <span>Accueil</span></Link></li>
                <li><Link to="/competences" className={currentPage === "competences" ? "nav-link scrollto active" : "nav-link scrollto"}><i className="bi bi-list-stars"></i> <span>Compétences</span></Link></li>
                <li><Link to="/cv" className={currentPage === "cv" ? "nav-link scrollto active" : "nav-link scrollto"}><i className="bi bi-file-earmark-text"></i> <span>Parcours</span></Link></li>
                <li><Link to="/portfolio" className={currentPage === "portfolio" ? "nav-link scrollto active" : "nav-link scrollto"}><i className="bi bi-grid-3x3-gap-fill"></i> <span>Portfolio</span></Link></li>
                <li><Link to="/me-contacter" className={currentPage === "contact" ? "nav-link scrollto active" : "nav-link scrollto"}><i className="bi bi-envelope-at-fill"></i> <span>Contact</span></Link></li>
              </ul>
            </nav>
            {/* <!-- .nav-menu --> */}
          </div>
        </header>
        
   



    )
}