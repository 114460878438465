import Header from "../Header/Header";
import { useState } from "react";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import "../../Styles/Style_general.css";
import Cookies from "./Cookies";
import Mobile from "../Header/Mobile";

export default function Template({ showMobileMenu, setShowMobileMenu }) {
  

  const handleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };


  return (
    <main className="grid-global">
     
      <Header />
      <Mobile showMobileMenu={showMobileMenu} handleMobileMenu={handleMobileMenu} />
      <Outlet />
      {/* <Footer /> */}
      {/* <Cookies /> */}
    </main>
  );
}
