
import 'bootstrap-icons/font/bootstrap-icons.css';





export default function Mobile({ showMobileMenu, handleMobileMenu }) {
 

 



    return (
       
        
          
      <i className={`bi d-xl-none mobile-nav-toggle ${showMobileMenu ? 'bi-x'  : 'bi-list'}` } onClick={handleMobileMenu}>{/* <!-- ======= Mobile nav toggle button ======= --> */}</i>
      
        
   



    )
}