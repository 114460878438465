import { useState, useEffect } from "react";
import confServeur from "../Components/Serveur/confServeur";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";

export default function Curriculum() {
  const urlServeur = confServeur.server;
  const urlImage = confServeur.storage;
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState("");
  // pour permettre la recherche que sur le titre
  // const search_parameters = ['title']

  //récupération des clés du tableau projects
  const search_parameters = Object.keys(Object.assign({}, ...projects));

  // récupération des projets en BD
  useEffect(() => {
    fetch(`${urlServeur}/portfolio`)
      .then((res) => res.json())
      .then((data) => setProjects(data))
      .catch((err) => console.log(err));
  }, []);

  // réinitialisation de l'input de recherche
  function clearSearch() {
    setSearch("");
  }
// some verifie l'existence des différents paramètres du tableau projects via la constante search_parameters puis opère une recherche des infos de l'input recherche. par défaut, tous les projets sont présents
  function toSearch(data) {
    return projects.filter((project) =>
      search_parameters.some((parameter) =>
        project[parameter].toString().toLowerCase().includes(search)
      )
    );
  }

  return (
    <article>
      {/* <!-- ======= Resume Section ======= --> */}
    <section id="resume" className="resume">
      <div className="container">

        <div className="section-title">
          <h2>Profil</h2>
          <p>En 2023, j'ai décidé de me lancer un nouveau challenge. J'ai donc entamé une reconversion en tant que concepteur et développeur d'application.</p>
        </div>

        <div className="row">
          <div className="col-lg-6" data-aos="fade-up">
            <h3 className="resume-title">Infos pratiques</h3>
            <div className="resume-item pb-0">
              <h4>Demba THIAM</h4>
              <p><em>20 ans d'expériences dans le numérique et la culture, je prend à coeur de fournir une bonne expérience utilisateur et de mener les projets en plaçant au centre le client.</em></p>
              <ul>
                <li>+33 7 63 59 13 81</li>
                <li>dembothiam@hotmail.com</li>
              </ul>
            </div>

            <h3 className="resume-title">Formation</h3>
            <div className="resume-item">
              <h4>Formation de Concepteur et développeur d'Application</h4>
              <h5>2023-2024</h5>
              <p><em>Prepavenir Formation - Franconville (95)</em></p>
              <p>languages : php, javascript, css, html<br />
              framework : nodeJS, react, symfony</p>
            </div>
            <div className="resume-item">
              <h4>BTS assistant de gestion &amp; </h4>
              <h5>2006</h5>
              <p><em>Lycée Van Gogh - Aubergenville (78)</em></p>
            </div>
            <div className="resume-item">
              <h4>Bac STT &amp; </h4>
              <h5>2004</h5>
              <p><em>Lycée Ronstand - Mantes la Jolie (78)</em></p>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3 className="resume-title">Expérience professionnelle</h3>
            <div className="resume-item">
              <h4>Chef de projet MOE - Decalog</h4>
              <h5>2019 - Present</h5>
              <p>Missions : Gestion de projet, évaluation des risques, rédaction du cahier de charges, suivi administratif.
Réalisations : 334 projets gérés dont 308 finalisés,
 1,5M € de CA.</p>
            </div>
            <div className="resume-item">
              <h4>Consultant - Formateur - Decalog</h4>
              <h5>2017 - 2019</h5>
              <p><strong>Missions :</strong> Formation sur site et à distance sur nos solutions, suivi pédagogique, création des supports, conseils en bibliothéconomie<br />
              <strong>Réalisations :</strong> 159 formations menées.</p>
            </div>
            <div className="resume-item">
              <h4>Coordinateur multimédia - Argenteuil</h4>
              <h5>2014 - 2017</h5>
              <p><strong>Missions :</strong>  Mise en place de l’espace multimédia, coordination et animation de projets numériques, communication, gestion du service....<br />
              <strong>Réalisations :</strong>  86 projets, 32 sessions d’initiation à l’outil informatique, 94% de satisfaction des personnes initiées.</p>
            </div>
            <div className="resume-item">
              <h4>Coordinateur multimédia - Les Mureaux</h4>
              <h5>2003 - 2014</h5>
              <p><strong>Missions :</strong>  Coordination et animation d’ateliers numériques, pilotage du site internet, accueil du public, maintenance informatique, responsable des acquisitions des documents numériques,....</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    {/* End Resume Section  */}

    </article>
  );
}
