export default function Politique() {
  return (
    <article>
      <h2>Politique de confidentialite</h2>
      <p>
        Cette politique de confidentialité décrit comment nous collectons,
        utilisons et protégeons les informations personnelles que vous nous
        fournissez lorsque vous utilisez ce site.
      </p>
      <h5>Collecte d'informations</h5>
      <p>
        Lorsque vous utilisez le formulaire de contact sur ce site, nous pouvons
        collecter les informations personnelles suivantes : Nom : Nous
        collectons votre nom afin de pouvoir vous identifier et vous adresser
        personnellement dans nos communications. Adresse e-mail : Nous
        collectons votre adresse e-mail afin de pouvoir vous contacter et
        répondre à vos demandes ou questions.
      </p>
      <h5>Utilisation des informations</h5>
      <p>
        Les informations personnelles que vous nous fournissez seront utilisées
        uniquement dans le but de répondre à vos demandes de renseignements, de
        fournir les services demandés et de vous contacter concernant votre
        demande. Nous ne partagerons pas vos informations personnelles avec des
        tiers sans votre consentement préalable, sauf si cela est nécessaire
        pour répondre à votre demande spécifique.
      </p>
      <h5>Sécurité des informations</h5>
      <p>
        Nous nous engageons à protéger la sécurité de vos informations
        personnelles. Nous mettons en place des mesures de sécurité appropriées
        pour prévenir tout accès non autorisé, toute divulgation, toute
        utilisation abusive ou toute perte de vos informations personnelles.
      </p>
      <h5>Cookies</h5>
      Ce site n'utilise pas de cookies pour collecter des informations
      personnelles.
      <h5>Liens vers des sites tiers</h5>
      <p>
        Ce site peut contenir des liens vers des sites web de tiers. Veuillez
        noter que nous ne sommes pas responsables des pratiques de
        confidentialité de ces autres sites. Nous vous encourageons à lire les
        déclarations de confidentialité de chaque site web que vous visitez.
      </p>
      <h5>Conservation des données</h5>
      Nous ne conserverons vos informations personnelles que pendant la durée
      nécessaire pour atteindre les finalités pour lesquelles elles ont été
      collectées ou conformément aux lois et réglementations applicables.
      <h5>Vos droits</h5>
      <p>
        Vous avez le droit de demander l'accès, la rectification ou la
        suppression des informations personnelles que nous détenons à votre
        sujet. Si vous souhaitez exercer ces droits, veuillez nous contacter à
        l'adresse indiquée ci-dessous.
      </p>
      <h5>Contact</h5>
      <p>
        Si vous avez des questions, des préoccupations ou des demandes
        concernant cette politique de confidentialité, veuillez nous contacter à
        l'adresse suivante :<strong>Demba THIAM</strong>
        <br />
        dembothiam@hotmail.com tel: 07 65 59 13 81
      </p>
    </article>
  );
}
