import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faPeopleArrows,
  faPhotoFilm,
  faGlobe,
  faPersonSwimming
} from "@fortawesome/free-solid-svg-icons";
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";



export default function Home() {
const Navigate = useNavigate()

 //si l'utilisateur a déjà un localStorage pour avoir visité la page d'accueil, alors il accède directement au site sans le réafficher
 useEffect(() => {
  const visited = JSON.parse(localStorage.getItem("pre-accueil"));
  if (visited===null) {
    Navigate('/acc')
  }
}, []);




  return (
    <article>
      <h1>Soyez la prochaine entreprise à s'inscrire dans mon Histoire</h1>
      <section>
        <h4>Profil</h4>
        <div className="flex profil">
          <figure>
            <img
              src="../../Images/Design/portrait.jpg"
              alt="portrait de face"
            />
            {/* <figcaption>Lorem</figcaption> */}
          </figure>
          <div className="">
            <h5>De l'imagination à votre service !</h5>
            <p>
              <span>"</span>Autonome, curieux, autodidacte et dynamique, mes
              différentes expériences m'ont permis de renforcer mes acquis et
              d'appréhender différents outils et techniques dans le domaine de
              l'informatique, de la culture et de l'art. <br /><br />En quête d'un nouveau
              challenge, j’ai entamé une formation à la conception et
              développement d'application web{" "}
              <strong>du 9 mai 2023 au 12 janvier 2024</strong>.<span>"</span>
            </p>
            <div>
              <a href="../../medias/CDACV2.pdf" download>
                <button className="button-primary">Mon CV</button>
              </a>
              <a href="../../medias/book pro Demba.pdf" download>
                <button className="button-secondary">Mon book pro</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <h4>Résumé</h4>
      <section className="presentation">
        <section>
          <div>
            <h6>Développement d'application</h6>
            <FontAwesomeIcon icon={faCode} />
            <p>
              Des compétences en perpétuelle évolution pour vous apporter
              solution et efficacité pour vos applications
            </p>
          </div>
          <div>
            <h6>Animation de Formations</h6>
            <FontAwesomeIcon icon={faPeopleArrows} />
            <p>
              Fort d'une expérience de formateur, mes compétences pédagogiques
              s'adaptent à bien des contextes
            </p>
          </div>
        </section>
        <figure>
          <img
            src="../../Images/Design/portrait.png"
            alt="illustration d'un portrait en mot me caractérisant avec mon nom, mon statut marital et parental, un code-barre"
          />
        </figure>
        <section>
          <div>
            <h6>Création de site internet</h6>
            <FontAwesomeIcon icon={faGlobe} />
            <p>
              besoin d'un site vitrine, d'un site marchand, ou d'un
              application... je réponds présent
            </p>
          </div>
          <div>
            <h6>Conception de contenu multimédia</h6>
            <FontAwesomeIcon icon={faPhotoFilm} />
            <p>
              De la vidéo à la photo, je vous accompagne de la prise de vue au
              montage.{" "}
            </p>
          </div>
        </section>
      </section>
    </article>
  );
}
