import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import confServeur from "../Components/Serveur/confServeur";

export default function AjoutCompetences() {
  const urlServeur = confServeur.server;
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [image, setImage] = useState("");
  const navigate = useNavigate();

  const validationForm = (e) => {
    e.preventDefault();
    console.log("valeur de type :" + type);
// création de l'objet à renvoyé en base de données
    const competence = new FormData();
    competence.append("title", title);
    competence.append("type", type);
    competence.append("image", image);

    console.log(competence);
// renvoi de l'objet en BD
    fetch(`${urlServeur}/competences/add`, {
      method: "POST",
      body: competence,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // renvoi vers la page des compétences si ok
        if (data) {
          navigate(`/competences/`);
        }
      })
      .catch(() =>
        alert("une erreur est survenue. Merci de vérifier votre réponse")
      );
  };

  return (
    <article>
      <h3>Déclarer un nouvelle compétence</h3>
      <form encType="multipart/form-data" onSubmit={validationForm}>
        <label htmlFor="title">Titre</label>
        <input
          type="text"
          name="title"
          placeholder="Titre"
          minLength="2"
          maxLength="50"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          required
        />
        <input
          type="file"
          name="image"
          id=""
          onChange={(e) => {
            setImage(e.target.files[0]);
          }}
        />
        <label htmlFor="type">Type de compétences</label>
        <div className="radio-input">
          <label>
            <input
              type="radio"
              name="type"
              value="Langage"
              onChange={(e) => setType(e.target.value)}
            />
            Langage
          </label>
          <label>
            <input
              type="radio"
              name="type"
              value="Framework"
              onChange={(e) => setType(e.target.value)}
            />{" "}
            Framework{" "}
          </label>
          <label>
            <input
              type="radio"
              name="type"
              value="Logiciel"
              onChange={(e) => setType(e.target.value)}
            />
            Logiciel
          </label>
          <label>
            <input
              type="radio"
              name="type"
              value="CMS"
              onChange={(e) => setType(e.target.value)}
            />
            CMS
          </label>
        </div>

        <button type="submit">Enregistrer</button>
      </form>
    </article>
  );
}
