import { Link } from "react-router-dom";

export default function Mentions() {
  return (
    <article>
      <h2>Mentions légales</h2>
      <p>
        En France, toute personne (physique ou morale) souhaitant publier un
        site web doit respecter un certain nombre d'obligations légales.
      </p>
      <h4>Éditeur :</h4>
      <p>
        Ce site internet est le portail officiel Demba THIAM. Pour toute
        information liée au fonctionnement, ou à l'utilisation de ce portail,
        vous pouvez nous contacter : -par courriel à : dembothiam@hotmail.com -
        par courrier à l'adresse Demba THIAM - 37 rue Gustave Courbet 95370
        Montigny les Cormeilles
      </p>
      <h4>Hébergeur :</h4>
      Herbergement en local
      <h4>Crédits :</h4>
      Les textes de ce portail sont rédigés par l'éditeur. Nous nous engageons à
      fournir un service de qualité auprès des internautes, en contrôlant au
      mieux la pertinence et la véracité de nos contenus. Cependant, nous ne
      garantissons pas que ce site soit exempt de défaut, d'erreur ou
      d'omission, d'inaccessibilité technique ou de falsification des contenus.
      les images sont quant à elles issues de la librairie fontAwesome, de
      flaticon ou de créations personnelles. L'animation de machine à écrire en
      page d'accueil est issue du module proposé par Matt Boldt -{" "}
      <Link to="https://www.mattboldt.com">www.mattboldt.com</Link>
      <h4>Conception graphique et développement:</h4>
      Demba THIAM - 37 rue Gustave Courbet 95370 Montigny les Cormeilles –
      France Téléphone : +33 (0) 7 63 59 13 81 dembothiam [arobase] hotmail.com
      <h4>Information des utilisateurs :</h4>
      Protection de la vie privée et droits d’accès Les adresses électroniques
      ou autres informations nominatives ne sont recueillies sur ce site qu'à la
      suite de votre communication volontaire, ces données n'étant utilisées que
      pour l'envoi de la réponse que vous attendez. Vous disposez, conformément
      aux dispositions de la loi "informatique et libertés" du 6 janvier 1978
      (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des
      personnes physiques à l'égard des traitements de données à caractère
      personnel), d'un droit d'accès, de rectification et de suppression
      concernant les données qui vous concernent. Vous pouvez également exercer
      ce droit en nous contactant par courrier postal adressé à l ’éditeur du
      site identifié plus haut dans cette page .<h5>Confidentialité</h5>
      Le propriétaire de ce site s'engagent à ne pas divulguer les informations
      concernant les utilisateurs. Aucun fichier ne peut être communiqué à des
      fins commerciales. Des traitements statistiques pourront être effectués
      sur les données non personnalisées.
      <h5>Liens hypertextes</h5>
      Les liens mis en place dans le cadre du présent portail en direction
      d'autres ressources présentes sur le réseau internet ne sauraient engager
      la responsabilité de l'éditeur de ce site.
      <h5>Droits réservés</h5>
      L'accès au portail de l'éditeur confère un droit d'usage privé et non
      exclusif du portail aux utilisateurs. La structure générale ainsi que les
      textes, images animées ou non, graphisme, logos, icônes et sons, y compris
      les documents téléchargeables composant ce portail, sont la propriété de
      l'éditeur en faisant partie (sauf mention contraire), ou sont publiés sur
      autorisation de leur propriétaire, et ont soumis au droit d'auteur et
      autres droits de la propriété intellectuelle. Les photos figurant sur ce
      portail sont la propriété de l'éditeur et ne peuvent être reproduites sans
      l'accord préalable de l'éditeur concernées. Toute reproduction totale ou
      partielle de ce portail est interdite par quelque procédé que ce soit sans
      autorisation expresse de l'éditeur concernées. Si malgré notre vigilance
      vous remarquez des illustrations non libres et souhaitez que nous les
      supprimions, contactez-nous à l’adresse courriel de l ’éditeur du site
      identifié plus haut dans cette page .<h5>Cookies</h5>
      Vous êtes informé que lors de vos visites sur le Site, un cookie peut
      s’installer automatiquement sur votre logiciel de navigation. Le cookie
      est un bloc de données qui ne permet pas de vous identifier en tant
      qu’utilisateur mais qui permet d’enregistrer des informations relatives à
      votre navigation sur le Site. Conformément aux recommandations de la CNIL,
      nous vous indiquons que le paramétrage du logiciel de navigation permet
      d’informer de la présence d’un cookie et notamment de refuser un cookie,
      de la manière suivante : Pour Google Chrome : Choisissez le menu Options
      puis Options avancées Cliquez sur Paramètres de contenu dans les options
      de confidentialité Pour Mozilla Firefox : Choisissez le menu "outil " puis
      "Options" Cliquez sur l'icône "vie privée" Repérez le menu "cookie" et
      sélectionnez les options qui vous conviennent Pour Microsoft Internet
      Explorer : Choisissez le menu "Outils" (ou "Tools"), puis "Options
      Internet" (ou "Internet Options"). Cliquez sur l'onglet "Confidentialité"
      (ou "Confidentiality") Sélectionnez le niveau souhaité à l'aide du
      curseur.
    </article>
  );
}
