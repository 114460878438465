import { useRef, useState, useEffect } from "react";
import confServeur from "../Components/Serveur/confServeur";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faPeopleArrows,
  faPhotoFilm,
  faGlobe,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";

export default function Competences() {
  //utilisation pour l'accès serveur et dossier de stockage des images
  const urlServeur = confServeur.server;
  const urlImage = confServeur.storage;
  const [competences, setCompetences] = useState([]);
  const [projects, setProjets] = useState([]);
  const [openSkill, setOpenSkill] = useState(null);
  const skillsRefs = useRef([]);


// Fonction pour animer les compétences
const animateSkills = () => {
  let progressBars = document.querySelectorAll(".progress .progress-bar");

  progressBars.forEach((el) => {
    el.style.width = el.getAttribute("aria-valuenow") + "%";
  });
};

// Effet secondaire pour activer l'animation des compétences lorsque le composant est monté
useEffect(() => {
  animateSkills(); // Exécute l'animation une fois lorsque le composant est monté
}, []); 



  

  //récupération des compétences
  useEffect(() => {
    fetch(`${urlServeur}/competences`)
      .then((res) => res.json())
      .then((data) => setCompetences(data))
      .catch((err) => console.log(err));
  }, []);
  //récupération des projets
  useEffect(() => {
    fetch(`${urlServeur}/portfolio/limited`)
      .then((res) => res.json())
      .then((data) => setProjets(data))
      .catch((err) => console.log(err));
  }, []);

  // accordéon de compétences avec l'utilisation de GSAP pour l'animation et useRef pour déterminer l'élément à masquer
  const handleSkill = (index) => {
    //gestion en if des éléments dejà ouverts et instancier le point de départ
    if (index === openSkill) {
      gsap.to(skillsRefs.current[index].querySelector(".skill-details"), {
        height: 0,
        duration: 1,
        ease: "power1.inOut",
        //ajout d'un code pour placer openSkill à null une fois l'ouverture effectuer
        onComplete: () => setOpenSkill(null),
      });
    } else {
      if (openSkill !== null) {
        gsap.to(skillsRefs.current[openSkill].querySelector(".skill-details"), {
          height: 0,
          duration: 1,
          ease: "power1.inOut",
        });
      }
      //animation d'ouverture
      setOpenSkill(index);
      gsap.fromTo(
        skillsRefs.current[index].querySelector(".skill-details"),
        { height: 0 },
        {
          height: "auto",
          duration: 1,
          ease: "power1.inOut",
        }
      );
    }
  };

  return (
    <article>

      <section className="section-title">

      <h2>Des compétences en perpétuelle évolution </h2>
      <h3>Compétences techniques</h3>
      </section>
      {/* <!-- ======= Skills Section ======= --> */}
    <section id="skills" className="skills section-bg">
      <div className="container">

        <div className="section-title">
          <h2>Hard skills</h2>
          <p>Je ne me lasse jamais d'apprendre... </p>
        </div>

        <div className="row skills-content">

          <div className="col-lg-6" data-aos="fade-up">

            <div className="progress">
              <span className="skill">React <i className="val">100%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div className="progress">
              <span className="skill">HTML <i className="val">100%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div className="progress">
              <span className="skill">CSS <i className="val">90%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div className="progress">
              <span className="skill">JavaScript <i className="val">90%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div className="progress">
              <span className="skill">MySQL <i className="val">80%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">

            <div className="progress">
              <span className="skill">Node Js <i className="val">100%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div className="progress">
              <span className="skill">Symfony <i className="val">100%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div className="progress">
              <span className="skill">PHP <i className="val">90%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div className="progress">
              <span className="skill">WordPress/Joomla <i className="val">100%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div className="progress">
              <span className="skill">Photoshop <i className="val">75%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div className="progress">
              <span className="skill">Première pro <i className="val">100%</i></span>
              <div className="progress-bar-wrap">
                <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </section>
    {/* <!-- End Skills Section --> */}

    <section>
    <div className="section-title">
          <h2>Soft skills</h2>
        </div>
    <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li><i className="bi bi-chevron-right"></i> <strong>Curiosité et réactivité</strong> </li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Ecoute active</strong></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Autonomie</strong></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  <li><i className="bi bi-chevron-right"></i> <strong>Sens du service</strong> </li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Créativité</strong> </li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Capacité d'apprentissage et pédagogique</strong></li>
                </ul>
              </div>
            </div>
    </section>
    </article>
  );
}
