import './App.css';
import { useEffect,useState } from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Template from './Components/Template/Template'
import Home from './Pages/Home';
import AjoutProjet from './Pages/AjoutProjet';
import Curriculum from './Pages/Curriculum';
import Competences from './Pages/Competences';
import AjoutCompetences from './Pages/AjoutCompetences';
import Mentions from './Pages/Mentionslegales';
import Contact from './Pages/Contact'
import Politique from './Pages/Politique';
import Prehome from './Pages/Prehome';
import Aboutme from './Pages/About';
import Portfolio from './Pages/Portfolio';
import Aos from 'aos';

function App() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  
  useEffect(() => {
    /**
   * Animation on scroll
   */
  window.addEventListener('load', () => {
    Aos.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    })
  });
  }, []);
  
  return (
    <main className={`App ${showMobileMenu ? 'mobile-nav-active' :'' }`}>
      <BrowserRouter>
      <Routes>
      <Route exact path="/acc" element={<Prehome />}/>
      <Route path="/" element={<Template showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu}/>}>
      <Route exact path="/other" element={<Home />}/>
        <Route exact path="/" element={<Aboutme />}/>
        <Route exact path="/portfolio/addproject" element={<AjoutProjet />}/>
        <Route exact path="/cv" element={<Curriculum />}/>
        <Route exact path="/competences" element={<Competences />}/>
        <Route exact path="/portfolio" element={<Portfolio />}/>
        <Route exact path="/competences/add" element={<AjoutCompetences />}/>
        <Route exact path="/mentions-legales" element={<Mentions />}/>
        <Route exact path="/me-contacter" element={<Contact />}/>
        <Route exact path="/polique-de-confidentialite" element={<Politique />}/>

      </Route>
      
      
      </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
