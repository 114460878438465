import { useState } from "react";
import { useNavigate } from "react-router-dom";
import confServeur from "../Components/Serveur/confServeur";
export default function Contact() {
  const urlServeur = confServeur.server;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const motifGen = /^([a-zA-Zéèàëïüûêöäôâ-]+)( [a-zA-Zéèàëïüûêöäôâ-]+)*$/;
  const motifEmail = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  const [erreursChamps, setErreursChamps] = useState({});
  const navigate = useNavigate();
  const nouvellesErreurs = {};

  const handleSubmit = (e) => {
    e.preventDefault();
    //vérification des champs

    if (!motifGen.test(lastName)) {
      nouvellesErreurs["nom"] = "Votre nom ne doit contenir que des lettres";
    }

    if (!motifGen.test(firstName)) {
      nouvellesErreurs["prenom"] =
        "Votre prénom ne doit contenir que des lettres";
    }

    if (!motifEmail.test(email)) {
      nouvellesErreurs["email"] =
        "Votre mail doit être dans le format monmail@domaine.fr";
    }

    setErreursChamps(nouvellesErreurs);

    //si aucune erreur, je traite les données
    if (Object.keys(nouvellesErreurs).length === 0) {
      const contactInfo = new FormData();
      contactInfo.append("firstName", firstName);
      contactInfo.append("lastName", lastName);
      contactInfo.append("email", email);
      contactInfo.append("message", message);

      // Effectuer la requête POST avec fetch
      fetch(`${urlServeur}/contact`, {
        method: "POST",
        body: contactInfo,
      })
        .then((response) => response.json())
        .then((data) => {
          // Traiter la réponse du serveur
          console.log(data);
          if (data) {
            setSuccessMessage(true)
              //renvoi de l'usager en page d'accueil. Penser à rajouter une temporalité à terme.
              .then(() => {
                navigate("/");
              });
          } else {
            setErrorMessage(true);
          }
        })
        .catch((error) => {
          console.error("Erreur:", error);
        });
    }
  };

  return (
    <article id="contact" className="contact">
      <div className="container">
      <div className="section-title">
          <h2>Contact</h2>
          <p>Pour tout complément d'information, n'hésitez pas à me compléter le formulaire ci-dessous.</p>
        </div>


        <div className="row" data-aos="fade-in">

<div className="col-lg-5 d-flex align-items-stretch">
  <div className="info">

    <div className="email">
      <i className="bi bi-envelope"></i>
      <h4>Email:</h4>
      <p>dembothiam@hotmail.com</p>
    </div>

    <div className="phone">
      <i className="bi bi-phone"></i>
      <h4>Téléphone:</h4>
      <p>+33 7 63 59 13 81</p>
    </div>

    <div className="address">
      <i className="bi bi-geo-alt"></i>
      <h4>Région:</h4>
      <p>Ile de France</p>
    </div>
    
  </div>

</div>

<div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
  <form onSubmit={handleSubmit} method="post" role="form" className="php-email-form">
    <div className="row">
      <div className="form-group col-md-6">
        <label for="name">Votre Nom</label>
        <input type="text" name="lastname" className="form-control" id="lastname"  onChange={(e) => setLastName(e.target.value)}
          minLength="2"
          maxLength="30" required/>
      </div>
      <div className="form-group col-md-6">
        <label for="name">votre Prénom</label>
        <input type="text" className="form-control" name="firstname" id="firstname"  onChange={(e) => setFirstName(e.target.value)}
          minLength="4"
          maxLength="100" required/>
      </div>
    </div>
    <div className="form-group">
    <label for="name">votre email</label>
        <input type="email" className="form-control" name="email" id="email"  onChange={(e) => setEmail(e.target.value)}
          minLength="4"
          maxLength="100" required/>
    </div>
    <div className="form-group">
      <label for="message">Message</label>
      <textarea className="form-control" name="message" rows="10" onChange={(e) => setMessage(e.target.value)} required></textarea>
    </div>
    <div className="my-3">
      <div className="loading">Loading</div>
      <div className="error-message"></div>
      <div className="sent-message">
        {/* gestion du message de la réussite ou de l'erreur */}
        {successMessage === true ? <p>Le message à bien été envoyé</p> : ""}
        {errorMessage === true ? (
          <p>
            Une erreur est survenue lors de l'envoi. Merci de vérifier vos
            réponses ou de recommencer ultérieurement
          </p>
        ) : (
          ""
        )}

      </div>
    </div>
    <div className="text-center"><button type="submit">Envoyer</button></div>
  </form>
</div>
</div>
</div>


    
    </article>
  );
}
